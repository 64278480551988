<template>
  <b-modal
      id="service-modal"
      title="Nuevo Ingreso"
      @ok="handle"
      @cancel="cancel"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
  >
    <b-form-group>
      <h3>Seleccione Servicios</h3>

      <!-- Dropdown with autocomplete -->
      <b-form-group label="Servicios">
        <b-form-select
            v-model="selectedService"
            :options="formattedCatalog"
            class="mb-2"
            placeholder="Seleccione un servicio"
            size="sm"
        ></b-form-select>
      </b-form-group>

      <!-- Add button -->
      <b-button variant="primary" size="sm" @click="addService">Agregar</b-button>

      <!-- Selected services table -->
      <b-table
          striped
          hover
          small
          :items="selected"
          :fields="tableFields"
          class="mt-3"
          responsive
      ></b-table>
    </b-form-group>

    <!-- Error message -->
    <b-alert variant="danger" :show="error" class="mt-3">
      Debe seleccionar al menos un servicio antes de continuar.
    </b-alert>
  </b-modal>
</template>

<script>
export default {
  props: ["catalog"],
  data() {
    return {
      selected: [], // Array to hold selected services
      selectedService: null, // Currently selected service from dropdown
      tableFields: [
        { key: "name", label: "Servicio" },
        { key: "price", label: "Precio (Q)" },
      ],
      error: false, // Error state
    };
  },
  computed: {
    formattedCatalog() {
      // Convert the catalog to an array of { value, text } objects for the dropdown
      return this.catalog.map((item) => {
        const [name, priceObj] = Object.entries(item)[0];
        const price = parseFloat(priceObj["$numberDecimal"]).toFixed(2);
        return {
          value: { name, price },
          text: `${name} - $${price}`,
        };
      });
    },
  },
  methods: {
    addService() {
      if (this.selectedService && !this.selected.some((s) => s.name === this.selectedService.name)) {
        this.selected.push(this.selectedService);
        this.selectedService = null; // Reset the dropdown after adding
        this.error = false; // Clear error if adding a service
      }
    },
    handle(e) {
      if (this.selected.length === 0) {
        this.error = true; // Show error if no services are selected
        console.log("ERROR");
        e.preventDefault(); // Prevent the modal from closing
        return;
      }

      this.error = false; // Clear error
      this.$emit("ok", e, this.selected); // Emit selected services on modal "ok"
      this.selected = []; // Clear selected services
    },
    cancel() {
      this.error = false; // Clear error
      this.selected = [];
    }
  },
};
</script>
