<template>
  <div>
    <input id="hamburger" class="hamburger" type="checkbox" @click="toggleHamburger()"/>
    <label class="hamburger" for="hamburger" id="ha">
      <i></i>
      <text>
        <close>close</close>
        <open>menu</open>
      </text>
    </label>
    <section class="drawer-list">
      <a href="#" v-shortkey="['ctrl', 'i']" @shortkey="shortcutEntry('p')"></a>
      <a href="#" v-shortkey="['ctrl', 'm']" @shortkey="shortcutEntry('m')"></a>
      <a href="#" v-shortkey="['ctrl', 'k']" @shortkey="shortcutEntry('c')"></a>
      <ul>
        <li><span class="menu-title">Operaciones</span></li>
        <li>
          <hr/>
        </li>
        <li><a href="#" v-b-modal.ingreso>
          <font-awesome-icon :icon="['fas', 'car']"/>
          Ingreso Vehiculos</a></li>
        <li><a href="#" v-b-modal.motos>
          <font-awesome-icon :icon="['fas', 'motorcycle']"/>
          Ingreso Motos</a></li>
        <li><a href="#" v-b-modal.diario>
          <font-awesome-icon :icon="['fas', 'calendar-day']"/>
          Tarifa Diaria</a></li>
        <li><a href="#" v-b-modal.mensual>
          <font-awesome-icon :icon="['fas', 'calendar-days']"/>
          Tarifa Mensual</a></li>
        <li><a href="#" v-b-modal.acceso>
          <font-awesome-icon :icon="['fas', 'shield']"/>
          Control De Acceso</a></li>
        <li>
          <hr/>
        </li>
        <template v-if="hasSpecialFees">
          <li><span class="menu-title">Tarifas Especiales</span></li>
          <li v-for="fee in special_fees.fees" :key="fee._id"><a href="#" v-b-modal="fee._id">
            <font-awesome-icon :icon="['fas', 'star']"/>
            {{ fee.name }}</a></li>
          <li>
            <hr/>
          </li>
        </template>
        <li>
          <a href="/report">
            <font-awesome-icon :icon="['fas', 'file']"/>
             Corte de caja
          </a>
        </li>
        <li>
          <a href="/logout">
            <font-awesome-icon :icon="['fas', 'power-off']"/> Salir
          </a>
        </li>
      </ul>
    </section>
    <b-navbar toggleable type="dark" variant="dark">
      <b-navbar-brand href="/">
        <img src='../../assets/images/logo-dark.png' height='50' alt='logo'/>
      </b-navbar-brand>
    </b-navbar>
    <b-container>
      <b-row class="text-center pt-5">
        <b-col class="col-md-12">
          <h1>{{ parking.name }}</h1>
        </b-col>
      </b-row>
      <b-col>
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar Placa"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-row>
        <b-col class="col-md-12">
          <b-table striped hover
                   :items="tickets"
                   :fields="fields"
                   :filter="filter"
                   :filter-included-fields="filterOn"
                   @filtered="onFiltered"
          >
            <template #cell(plate)="data">
              {{ data.item.plate }}
              <span v-if="data.item.services && data.item.services.length > 0">
                <a href="#" @click="showServices(data.item)"><font-awesome-icon :icon="['fas', 'eye']"/></a>
              </span>
            </template>
            <template #cell(entry)="data">
              {{ formatDate(data.item.entry) }}
            </template>
            <template #cell(Accion)="data">
              <div v-if="data.item.amount_due >= 0 && !data.item.paid && data.item.exit !== null">
                <b-button variant="danger" :id=data.item._id @click="showFee(data.item, $event)">
                  <font-awesome-icon :icon="['fas', 'file-invoice']"/>
                </b-button>
              </div>
              <div v-else>
                <b-button variant="success" :id=data.item._id @click="calcFee(data.item, $event)">
                  <font-awesome-icon :icon="['fas', 'file-invoice']"/>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>

    <PlateModal @ok="handleIngreso" modal-id="ingreso" type="H"/>
    <PlateModal @ok="handleIngreso" modal-id="diario" type="D"/>
    <PlateModal @ok="handleIngreso" modal-id="mensual" type="M"/>
    <PlateModal @ok="handleIngreso" modal-id="motos" type="MC"/>
    <PlateModal @ok="handleIngreso" modal-id="acceso" type="ACL"/>

    <template v-if="hasSpecialFees">
      <div v-for="fee in special_fees.fees" :key="fee._id">

        <PlateModal @ok="handleIngreso" :modal-id="fee._id" :type="fee.type"/>
      </div>
    </template>

    <b-modal
        id="recibo"
        ref="recibo"
        size="lg"
        title="Checkout"
        :ok-title=okTitle
        @ok="facturar"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close>
      <form id="factura">
        <b-container>
          <b-row class="text-center">
            <b-col class="col-md-12">
              <h3>{{ parking.name }}</h3>
              <p>{{ parking.address }}</p>
            </b-col>
          </b-row>
          <table class="table table-striped">
            <tr>
              <td><b>Entrada</b></td>
              <td>{{ formatDate(this.invoice.entry) }}</td>
            </tr>
            <tr>
              <td><b>Salida</b></td>
              <td>{{ formatDate(this.invoice.exit) }}</td>
            </tr>
            <tr>
              <td><b>Total</b></td>
              <td>Q. {{ this.invoice.amount_due.toFixed(2) }}</td>
            </tr>
            <tr v-if="this.invoice.discount">
              <td><b>Descuento</b></td>
              <td>Q. {{ (this.invoice.discount.toFixed(2)) }}</td>
            </tr>
          </table>
          <b-row>
            <b-col class="col-md-6">
              <b-form-group>
                <label># Placa</label>
                <b-form-input id="plate" v-model="invoice.plate" placeholder="Placa" disabled></b-form-input>
              </b-form-group>
              <b-form-group v-if="!isReceipt">
                <label>Nombre</label>
                <b-form-input id="plate" v-model="customer_data.name" placeholder="Nombre"></b-form-input>
              </b-form-group>
              <b-form-group v-if="!isReceipt">
                <label>NIT</label>
                <b-form-input id="plate" v-model="customer_data.tax_id" placeholder="NIT"></b-form-input>
              </b-form-group>
              <b-form-group v-if="!isReceipt">
                <label>Dirección</label>
                <b-form-input id="plate" v-model="customer_data.address" placeholder="Dirección"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col class="col-md-6">
              <b-form-group>
                <label>Total</label>
                <b-form-input id="total" v-model="payment_data.due" placeholder="Total" disabled></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Forma De Pago</label>
                <b-form-select v-model="payment_data.method" :options="payment_methods"
                               @change="payMethodChange"></b-form-select>
              </b-form-group>
              <b-form-group>
                <label>Pago</label>
                <b-form-input id="plate" v-model="payment_data.amount" @change="calcChange"
                              placeholder="Pago"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Cambio</label>
                <b-form-input id="plate" v-model="payment_data.change" placeholder="Cambio" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </form>
    </b-modal>

    <b-modal
        id="services"
        ref="services"
        title="Servicios"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close>
      <span v-if="see_services && see_services.length > 0">
          <ul>
            <li v-for="(service, index) in see_services" :key="index">
              {{ service.name }} - Q{{ service.price }}
            </li>
          </ul>
        </span>
    </b-modal>

    <ServicesModal
      :catalog=additional_services
     @ok="handleIngresEspecial"></ServicesModal>

    <div id="ticket" style="font-size: xx-small">
      <b>{{ parking.name }}</b>
      <p>{{ parking.address }}</p>
      <p><b>{{ placa }}</b></p>
      <p>{{ (new Date()).toLocaleDateString() }} {{ (new Date()).toLocaleTimeString() }}</p>

      <span v-if="selected_services && selected_services.length > 0">
          <ul>
            <li v-for="(service, index) in selected_services" :key="index">
              {{ service.name }} - Q{{ service.price }}
            </li>
          </ul>
        </span>

      <p>No nos hacemos responsables por daños y perjuicios <br/> totales o parciales ocasionados a su vehículo</p>
      <span v-if="ownerProfile.disclaimer">
        <b>{{ownerProfile.disclaimer}}</b>
      </span>
    </div>

    <ContingencyInvoice
        :number=cont_number
        :isGuatefacturas=isGuatefacturas()
        :ownerProfile=ownerProfile
        :payment_data=payment_data
        :customer_data=customer_data
        :invoice=invoice
        :placa=placa
        :entry=formatDate(invoice.entry)
        :exit=formatDate(invoice.exit)
        :phrases=phrases
        :parking=parking
        :invoice_id=invoice._id
    />
    <div id="printable-invoice">
      <span v-if="!isReceipt">
        Documento Tributario Electrónico <br/>
        Factura <br/>
        Autorización:<br/>
        {{ fel_auth }}<br/>
        Número: {{ number }}<br/>
        Serie: {{ serial }}<br/>
        <span v-if="invoice.contingency">
              Numero De Acceso: {{ invoice.contingency }}<br/>
            </span>
        <b>Fecha: </b> {{ (new Date()).toLocaleDateString() }} <span
          v-if="!isGuatefacturas">{{ (new Date()).toLocaleTimeString() }}</span><br/>
      </span>
      <span v-else>
        RECIBO DE CAJA
      </span>
      <hr/>
      <b>Datos del vendedor:</b> <br/>
      <b>{{ parking.name }}</b><br/>
      <span v-if="!isReceipt">
        {{ parking.address }}<br/>
        {{ ownerProfile.commercial_name }}<br/>
        Nit: {{ ownerProfile.tax_id }}<br/>
        <hr/>
        <b>Datos del comprador:</b><br/>
        <b>Cliente: </b> {{ customer_data.name }}<br/>
        <b>NIT: </b> {{ customer_data.tax_id }}<br/>
        <b>Dirección: </b> {{ customer_data.address }}<br/>
      </span>
      <b>Placa: </b> {{ placa }}<br/>
      <hr/>
      <span v-if="invoice.services && invoice.services.length > 0">
        Servicio De CarWash <br/>
          <ul>
              <li v-for="(service, index) in invoice.services" :key="index">
                {{ service.name }} - Q{{ service.price }}
              </li>
          </ul>
        <b>Total: </b> Q.{{ parseFloat(payment_data.due).toFixed(2) }}
        <hr/>
        <b>Su pago: </b> Q. {{ parseFloat(payment_data.amount).toFixed(2) }}<br/>
        <b>Forma de pago: </b> {{ payment_data.method }}<br/>
        <b>Cambio: </b> Q. {{ parseFloat(payment_data.change).toFixed(2) }}
      </span>
      <span v-else>
        Servicio De Parqueo <br/>
        <b>Entrada: </b>{{ formatDate(invoice.entry) }} <br/>
        <b>Salida: </b>{{ formatDate(invoice.exit) }} <br/>
        <b>Total: </b> Q.{{ parseFloat(payment_data.due).toFixed(2) }}
        <hr/>
        <b>Su pago: </b> Q. {{ parseFloat(payment_data.amount).toFixed(2) }}<br/>
        <b>Forma de pago: </b> {{ payment_data.method }}<br/>
        <b>Cambio: </b> Q. {{ parseFloat(payment_data.change).toFixed(2) }}
      </span>
      <hr/>
      <span v-if="!isReceipt">
        <div v-for="frase of phrases" :key="frase">
          {{ frase }}.<br/>
        </div>
        <hr/>
        <b>Certificador: </b> {{ ownerProfile.certifier }}
        <span v-if="isGuatefacturas">
            SOCIEDAD ANONIMA, Nit: 5640773-4
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
#ticket {
  display: none;
}

#printable-invoice {
  display: none;
}

#contingency-invoice {
  display: none;
}
</style>

<script>
import '@/assets/styles/drawer.scss'
import {getParking} from '@/services/parking.service'
import {
  closeTicket,
  createTicket,
  getOpenTickets,
  payTicket,
  setTicketContingency,
  getTaxInfo
} from '@/services/tickets.service'
import {
  getSpecialFees
} from '@/services/fees.service'
import nitValidator from '@/utils/gt-tax-id'
import cuiValidator from '@/utils/gt-cui'
import print from 'print-js'
import QRCode from 'qrcode'
import PlateModal from '@/components/PlateModal'
import {getFrases} from '@/utils/frases'
import ContingencyInvoice from '@/components/ContingencyInvoice'
import {me} from "@/services/operators.service";
import ServicesModal from "@/components/ServicesModal";

export default {
  components: {
    ServicesModal,
    ContingencyInvoice,
    PlateModal
  },
  data() {
    return {
      okTitle: "Generar Factura",
      lastClickedId: '',
      ham: 0,
      filter: null,
      filterOn: ['plate'],
      totalRows: 1,
      currentPage: 1,
      plate_prefill: '',
      cont_number: 0,
      opProfile: {},
      ownerProfile: {},
      phrases: [],
      parking: {},
      invoice: {
        entry: '',
        exit: '',
        amount_due: 0,
        discount: 0,
      },
      customer_data: {
        name: '',
        tax_id: '',
        address: 'CIUDAD'
      },
      payment_methods: [
        'TARJETA',
        'EFECTIVO'
      ],
      payment_data: {
        due: 0,
        amount: '',
        change: '',
        method: ''
      },
      tickets: [],
      special_fees: [],
      additional_services: [],
      selected_services: [],
      see_services: [],
      placa: '',
      fel_auth: '',
      number: '',
      serial: '',
      selected_fee: '',
      ticket_id_print: '',
      qr_code: '',
      isReceipt: false,
      fields: [
        {
          key: 'plate',
          label: 'Placa'
        },
        {
          key: 'entry',
          label: 'Entrada'
        },
        {
          key: 'type',
          label: 'Tipo'
        },
        'Accion'
      ]
    }
  },
  async mounted() {
    window.addEventListener("offline",
        ()=> console.log("No Internet")
    );

    window.addEventListener("online",
        ()=> console.log("Connected Internet")
    );

    const _me = await me()
    if (!_me.operator || !_me.owner) {
      localStorage.clear()
      await this.$router.push('login')
    } else {
      this.special_fees = await getSpecialFees(_me.operator.parking_id)
      this.ownerProfile = _me.owner
      this.opProfile = _me.operator
      this.parking = await getParking(_me.operator.parking_id)
      this.tickets = await getOpenTickets(this.parking._id)
      this.phrases = getFrases(this.ownerProfile.phrases)
      this.additional_services = _me.owner.catalog

      if (this.ownerProfile.certifier == 'MOCKCERT') {
        this.isReceipt = true;
      }
    }

    const api_base = process.env.VUE_APP_API_BASE_URL
    const updateTicketEventSource = new EventSource(`${api_base}/v1/tickets/stream/${this.parking._id}`)
    updateTicketEventSource.addEventListener('UPDATE_TICKETS', async (e) => {
      const updates = JSON.parse(e.data)
      if (updates.update) {
        this.tickets = await getOpenTickets(this.parking._id)
      }
    })

  },
  methods: {
    async shortcutEntry(type) {
      switch (type) {
        case 'p':
          this.plate_prefill = "P-"
          this.$root.$emit('bv::show::modal', 'ingreso')
          await this.delay(100)
          document.getElementById('plate').value = 'P-'
          document.getElementById('plate').focus()
          break
        case 'm':
          this.plate_prefill = "M-"
          this.$root.$emit('bv::show::modal', 'motos')
          await this.delay(100)
          document.getElementById('plate').value = 'M-'
          document.getElementById('plate').focus()
          break
        case 'c':
          this.plate_prefill = "C-"
          this.$root.$emit('bv::show::modal', 'ingreso')
          await this.delay(100)
          document.getElementById('plate').value = 'C-'
          document.getElementById('plate').focus()
          break
      }
    },
    isGuatefacturas() {
      return (this.ownerProfile.certifier === "GUATEFACTURAS")
    },
    formatNumber(num) {
      if (num < 10) {
        return `0${num}`
      }
      return num
    },
    hasSpecialFees() {
      return this.special_fees.length > 0
    },
    formatDate(input) {

      if (!input) {
        return 'N/A'
      }
      const d = new Date(input)

      let localeTime = d.toLocaleTimeString('es-GT', {
        hour12: true
      })
      const parts = localeTime.split(':')
      localeTime = `${parts[0]}:${parts[1]} ${parts[2].split(' ')[1]}`

      return `${d.toLocaleDateString()} ${localeTime}`
    },
    payMethodChange() {
      if (this.payment_data.method === 'TARJETA') {
        this.payment_data.amount = this.payment_data.due
        this.payment_data.change = 0
      } else {
        this.payment_data.amount = ''
        this.payment_data.change = ''
      }
    },
    async showFee(ticket) {
      this.lastClickedId = ticket._id
      this.invoice = ticket
      this.placa = ticket.plate

      const taxInfo = await getTaxInfo(ticket.plate, ticket.parking_id)
      if (taxInfo) {
        this.customer_data.tax_id = taxInfo.tax_id
        this.customer_data.name = taxInfo.name
      }

      const discount = this.invoice.discount ? parseFloat(this.invoice.discount.toFixed(2)) : 0
      const due = parseFloat(this.invoice.amount_due.toFixed(2))

      this.payment_data.due = (discount === 0) ? due : (due - discount).toFixed(2)
      this.$refs['recibo'].show()
    },
    async calcFee(ticket, e) {
      this.lastClickedId = e?.target.id

      const payload = {
        ticket_id: ticket._id,
        parking_id: this.parking._id,
        plate: ticket.plate
      }

      const taxInfo = await getTaxInfo(payload.plate, payload.parking_id)

      this.placa = ticket.plate
      this.invoice = await closeTicket(payload)
          .catch(() => {
            this.$swal.fire({
              icon: 'error',
              title: 'Error',
              html: 'Tarifa no configurada. Consulte a su administrador.'
            })
            return null
          })

      if (this.invoice && ticket.type !== 'ACL') {
        const total = this.invoice.amount_due
        const discount = this.invoice.discount ? this.invoice.discount : 0
        const grand_total = total - discount
        this.payment_data.due = grand_total.toFixed(2)

        if (taxInfo) {
          this.customer_data.tax_id = taxInfo.tax_id
          this.customer_data.name = taxInfo.name
        }

        this.$refs['recibo'].show()

        this.tickets = await getOpenTickets(this.parking._id)
      } else {
        const invoice = {
          name: this.customer_data.name,
          tax_id: this.customer_data.tax_id,
          address: this.customer_data.address,
          due: 0,
          amount: 0,
          change: 0,
          method: 'CARD'
        }

        await payTicket(ticket._id, invoice, this.parking._id)

        this.tickets = await getOpenTickets(this.parking._id)
      }

    },
    calcChange() {
      if (this.payment_data.method === "TARJETA") {
        this.payment_data.change = 0.00
      } else if (this.payment_data.method === "VOUCHER") {
        this.payment_data.change = 0.00
      } else {
        this.payment_data.change = parseFloat(this.payment_data.amount) - parseFloat(this.payment_data.due)
      }
    },
    resetInvoice() {
      this.customer_data.name = ''
      this.customer_data.tax_id = ''
      this.customer_data.address = 'CIUDAD'
      this.payment_data.due = 0
      this.payment_data.amount = ''
      this.payment_data.change = ''
      this.payment_data.method = ''
      this.placa = ''
      this.fel_auth = ''
      this.serial = ''
      this.number = ''
    },
    async facturar() {
      const invoice = {
        name: this.customer_data.name,
        tax_id: this.customer_data.tax_id,
        address: this.customer_data.address,
        due: this.payment_data.due,
        amount: this.payment_data.amount,
        change: this.payment_data.change,
        method: this.payment_data.method,
        placa: this.placa,
      }

      let errors = ''
      const nitOptions = {
        clean: true
      }
      const validNit = nitValidator.validateNIT(this.customer_data.tax_id, nitOptions)
      const validCUI = cuiValidator.validateCUI(this.customer_data.tax_id)
      if (this.customer_data.tax_id === 'CF' && this.payment_data.due > 2500 && !this.isReceipt) {
        errors += '- Es necesario proveer un NIT o CUI para montos mayores a Q2500.00</br>'
      }
      if (this.customer_data.tax_id !== 'CF' && (!validNit.resultado && !validCUI) && !this.isReceipt) {
        errors += '- El NIT o CUI es invalido</br>'
      }
      if (this.customer_data.name === '' && !this.isReceipt) {
        errors += '- Se requiere el nombre del cliente</br>'
      }
      if (this.payment_data.method === '') {
        errors += '- Indique forma de pago</br>'
      }
      if ((!this.payment_data.amount || isNaN(this.payment_data.amount)) && (this.payment_data.due > 0 && this.payment_data.method !== 'VOUCHER')) {
        errors += '- Indique monto recibido</br>'
      }

      if (!errors) {
        if (this.invoice.contingency) {
          invoice.contingency = this.invoice.contingency
        }
        const certInvoice = await payTicket(this.invoice._id, invoice, this.parking._id)
            .catch((error) => {
              const certInvoiceErrorMessage = JSON.parse(error.message)
              if (certInvoiceErrorMessage.status === 'Ticket ya fue pagado') {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  showCancelButton: true,
                  confirmButtonText: 'OK',
                  html: certInvoiceErrorMessage.status
                }).then(async () => {
                  this.resetInvoice()
                })
              } else {
                this.$swal.fire({
                  icon: 'error',
                  title: 'Error',
                  showCancelButton: true,
                  confirmButtonText: 'Imprimir Contingencia',
                  html: JSON.parse(error.message).message
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    this.createContingencyNumber()
                    await setTicketContingency(this.invoice, this.cont_number)
                    this.tickets = await getOpenTickets(this.parking._id)

                    await this.delay(100)
                    print({
                      printable: 'contingency-invoice',
                      style: 'div { text-align: center; font-size: xx-small; max-width:219px; }',
                      scanStyles: false,
                      type: 'html'
                    })
                  }
                  this.resetInvoice()
                })
              }
            })

        if (certInvoice && this.payment_data.due > 0) {
          this.fel_auth = certInvoice.factura.invoice.authorization
          this.serial = certInvoice.factura.invoice.serial
          this.number = certInvoice.factura.invoice.number

          this.customer_data.name = certInvoice.factura.invoice.name

          // print dialog shows before vue updates the data somehow
          await this.delay(100)
          print({
            printable: 'printable-invoice',
            style: 'div { text-align: center; font-size: xx-small; max-width:219px; }',
            scanStyles: false,
            type: 'html'
          })

        }
        this.accInvoice(invoice)
        this.resetInvoice()
        this.$refs['recibo'].hide()
        this.tickets = await getOpenTickets(this.parking._id)
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          html: errors
        })
      }
    },
    generateRandom(len) {
      let number = ""
      const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      for (let i = 0; i < len; i++) {
        const char = numbers[Math.floor(Math.random() * numbers.length)]
        number += char
      }

      return number
    },
    createContingencyNumber() {
      if (this.invoice.contingency) {
        this.cont_number = this.invoice.contingency
      } else {
        this.cont_number = this.generateRandom(9)
      }
    },
    async delay(ms) {
      return new Promise(res => setTimeout(res, ms))
    },
    async createQR(ticketId) {
      return QRCode.toDataURL(ticketId);
    },
    async handleIngresEspecial(e, servicios) {
      const payload = {
        operator_id: this.opProfile._id,
        parking_id: this.parking._id,
        plate: this.placa,
        type: "S",
        services: servicios
      }
      console.log(servicios)
      this.selected_services = servicios

      await this.createTicket(payload)
    },
    async handleIngreso(e, placa, type) {
      this.placa = placa.toUpperCase()
      const isInside = this.tickets.filter((t) => {
        return t.plate === this.placa
      })
      if (isInside.length > 0) {
        e.preventDefault()
        this.placa = ''
        this.$swal.fire({title: 'Vehiculo ya ingreso', position: 'top'})
      } else {
        const hasServices = this.ownerProfile.services
        if(hasServices) {
          this.$root.$emit('bv::show::modal', 'service-modal')

          return
        }

        const payload = {
          operator_id: this.opProfile._id,
          parking_id: this.parking._id,
          plate: this.placa,
          type: type
        }

        const plateRegex = new RegExp('[P|M|C|O|A|MI|CD|CC|U|TRC|TC|DIS]\\-\\d{3}[A-Z]{3}')

        if (!plateRegex.test(payload.plate)) {
          e.preventDefault()
          this.placa = ''
          this.$swal.fire({title: 'Placa invalida', position: 'top'})
        } else {
          await this.createTicket(payload)
        }
      }
    },
    async createTicket(payload) {
      const ticket = await createTicket(payload)

      if(ticket.status === 'error') {
        this.$swal.fire({
          icon: 'error',
          title: 'Error',
          html: ticket.message
        })
      } else if (ticket) {
        this.tickets.push(ticket)

        this.ticket_id_print = await this.createQR(ticket._id)
            .catch(error => console.log(error))

        // TODO: QR wont print as html type
        print({
          printable: this.ticket_id_print,
          type: 'image',
          header: document.getElementById('ticket').innerHTML
        })

        this.ticket_id_print = ''
        this.placa = ''
        this.selected_services = []
      }

      if (this.ham === 1) {
        document.getElementById('hamburger').click()
        this.toggleHamburger()
      }
    },
    accInvoice(invoice) {
      let sessionData = JSON.parse( localStorage.getItem('session_invoices') )
      if(!sessionData) {
        sessionData = []
      }
      sessionData.push(invoice)

      localStorage.removeItem('session_invoices')
      localStorage.setItem('session_invoices', JSON.stringify(sessionData))
    },
    documentWording(key) {
      let documentName = 'Factura';
      if(this.isReceipt) {
        documentName = 'Recibo'
      }

      const names = {
        documentName,
      }

      return names[key]
    },
    frases(phrases) {
      return getFrases(phrases)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      if(filteredItems.length <= 0) {
        const ticket = this.tickets.filter(t => t._id === this.filter).pop()
        if(ticket.amount_due >= 0 && !ticket.paid && ticket.exit !== null) {
          this.showFee(ticket)
        } else {
          this.calcFee(ticket)
        }

        this.filter = ""
      }

    },
    toggleHamburger() {
      if (this.ham === 0) {
        this.ham = 1
      } else {
        this.ham = 0
      }
    },
    showServices(ticket) {
      this.see_services = ticket.services
      this.$root.$emit('bv::show::modal', 'services')
    }
  }
}
</script>
